<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-10, .cls-19, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke-linecap: square;
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#glass-pattern);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }

      .cls-12 {
        fill: url(#glass-pattern);
      }

      .cls-13 {
        fill: url(#glass-pattern);
      }

      .cls-14 {
        fill: url(#glass-pattern);
      }

      .cls-15 {
        fill: url(#glass-pattern);
      }

      .cls-16 {
        fill: url(#glass-pattern);
      }

      .cls-17 {
        fill: url(#glass-pattern);
      }

      .cls-18 {
        fill: url(#glass-pattern);
      }

      .cls-19 {
        fill: url(#inox-gradient-4);
      }
    </svg:style>
    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.16"
                    :y1="doorTopHeight1 + 148.47"
                    :x2="doorLeftWidth1 + 16.75"
                    :y2="doorTopHeight1 + 148.47"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 35.02"
                    :y1="doorTopHeight1 + 153.16"
                    :x2="doorLeftWidth1 + 63.5"
                    :y2="doorTopHeight1 + 153.16"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 77.16"
                    :y1="doorTopHeight1 + 153.16"
                    :x2="doorLeftWidth1 + 104.8"
                    :y2="doorTopHeight1 + 153.16"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 1334.95"
                    :y1="doorTopHeight1 + 7332.65"
                    :x2="doorLeftWidth1 + 1334.95"
                    :y2="doorTopHeight1 + 7292.66"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth1 + 1345.67"
                    :y1="doorTopHeight1 + 7323.32"
                    :x2="doorLeftWidth1 + 1345.67"
                    :y2="doorTopHeight1 + 7297.53"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 1324.08"
                    :y1="doorTopHeight1 + 7323.37"
                    :x2="doorLeftWidth1 + 1324.08"
                    :y2="doorTopHeight1 + 7297.53"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 1317.38"
                    :y1="doorTopHeight1 + 7327.75"
                    :x2="doorLeftWidth1 + 1317.38"
                    :y2="doorTopHeight1 + 7307.01"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-4"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 1352.44"
                    :y1="doorTopHeight1 + 7327.75"
                    :x2="doorLeftWidth1 + 1352.44"
                    :y2="doorTopHeight1 + 7306.97"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-5"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 91.12"
                    :y1="doorTopHeight1 + 126.67"
                    :x2="doorLeftWidth1 + 91.12"
                    :y2="doorTopHeight1 + 102.45"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-6"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 91.12"
                    :y1="doorTopHeight1 + 152.41"
                    :x2="doorLeftWidth1 + 91.12"
                    :y2="doorTopHeight1 + 128.19"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-7"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 91.12"
                    :y1="doorTopHeight1 + 178.15"
                    :x2="doorLeftWidth1 + 91.12"
                    :y2="doorTopHeight1 + 153.93"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-8"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 91.12"
                    :y1="doorTopHeight1 + 203.89"
                    :x2="doorLeftWidth1 + 91.12"
                    :y2="doorTopHeight1 + 179.67"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-9"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 49.27"
                    :y1="doorTopHeight1 + 126.67"
                    :x2="doorLeftWidth1 + 49.27"
                    :y2="doorTopHeight1 + 102.45"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-10"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 49.27"
                    :y1="doorTopHeight1 + 152.41"
                    :x2="doorLeftWidth1 + 49.27"
                    :y2="doorTopHeight1 + 128.19"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-11"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 49.27"
                    :y1="doorTopHeight1 + 178.15"
                    :x2="doorLeftWidth1 + 49.27"
                    :y2="doorTopHeight1 + 153.93"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-12"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 49.27"
                    :y1="doorTopHeight1 + 203.89"
                    :x2="doorLeftWidth1 + 49.27"
                    :y2="doorTopHeight1 + 179.67"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 1336.24"
                    :y1="doorTopHeight1 + 7501.38"
                    :x2="doorLeftWidth1 + 1336.24"
                    :y2="doorTopHeight1 + 7473.34"
                    xlink:href="#handle-gradient"/>
    <g id="C9">
      <polyline id="inox"
                v-if="showInox"
                class="cls-4"
                :points="`
                ${doorLeftWidth1 + 35.02} ${doorTopHeight1 + 97.56}
                ${doorLeftWidth1 + 35.02} ${doorTopHeight1 + 208.76}
                ${doorLeftWidth1 + 63.5} ${doorTopHeight1 + 208.76}
                ${doorLeftWidth1 + 63.5} ${doorTopHeight1 + 97.56}
                ${doorLeftWidth1 + 35.02} ${doorTopHeight1 + 97.56}
                `"/>
      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-5"
                :points="`
                ${doorLeftWidth1 + 77.16} ${doorTopHeight1 + 97.56}
                ${doorLeftWidth1 + 77.16} ${doorTopHeight1 + 208.76}
                ${doorLeftWidth1 + 104.8} ${doorTopHeight1 + 208.76}
                ${doorLeftWidth1 + 104.8} ${doorTopHeight1 + 97.56}
                ${doorLeftWidth1 + 77.16} ${doorTopHeight1 + 97.56}
                `"/>
      <g id="Rounded_glass" data-name="Rounded glass">
        <path id="inox-3"
              v-if="showInox"
              data-name="inox"
              class="cls-6"
              :d="`
              M${doorLeftWidth1 + 1370},${doorTopHeight1 + 7327.75}
              v4.9
              h-70.07
              v-5
              a35,35,0,1,1,70.07,0
              v0.05
              Z
              `"
              transform="translate(-1265.04 -7249.7)"/>
        <path id="glass"
              class="cls-7"
              :d="`
              M${doorLeftWidth1 + 1335.76},${doorTopHeight1 + 7297.53}
              a30.11,30.11,0,0,1,19.81,8.15
              l-0.07.07-17.57,17.57
              h0
              a9.5,9.5,0,0,0-2.17-.44
              v-25.36
              Z
              `"
              transform="translate(-1265.04 -7249.7)"/>
        <path id="glass-2"
              data-name="glass"
              class="cls-8"
              :d="`
              M${doorLeftWidth1 + 1314.2},${doorTopHeight1 + 7305.74}
              a30.11,30.11,0,0,1,19.75-8.21
              v25.37
              a9.56,9.56,0,0,0-2.13.47
              Z
              `"
              transform="translate(-1265.04 -7249.7)"/>
        <path id="glass-3"
              data-name="glass"
              class="cls-9"
              :d="`
              M${doorLeftWidth1 + 1326.36},${doorTopHeight1 + 7327.75}
              h-21.65
              v0
              a30.14,30.14,0,0,1,8.22-20.72
              l17.12,17.18
              a9.66,9.66,0,0,0-3.47,3.53
              v0
              `"
              transform="translate(-1265.04 -7249.7)"/>
        <path id="glass-4"
              data-name="glass"
              class="cls-10"
              :d="`
              M${doorLeftWidth1 + 1339.73},${doorTopHeight1 + 7324.12}
              l17.09-17.1,0.05,0
              a30.16,30.16,0,0,1,8.26,20.78
              h-21.81
              l-0.08-.14
              a9.66,9.66,0,0,0-3.5-3.48
              h0
              Z
              `"
              transform="translate(-1265.04 -7249.7)"/>
      </g>
      <polyline id="glass-5"
                data-name="glass"
                class="cls-11"
                :points="`
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 102.45}
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 126.67}
                ${doorLeftWidth1 + 100.27} ${doorTopHeight1 + 126.67}
                ${doorLeftWidth1 + 100.27} ${doorTopHeight1 + 102.45}
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 102.45}
                `"/>
      <polyline id="glass-6"
                data-name="glass"
                class="cls-12"
                :points="`
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 128.19}
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 152.41}
                ${doorLeftWidth1 + 100.27} ${doorTopHeight1 + 152.41}
                ${doorLeftWidth1 + 100.27} ${doorTopHeight1 + 128.19}
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 128.19}
                `"/>
      <polyline id="glass-7"
                data-name="glass"
                class="cls-13"
                :points="`
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 153.93}
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 178.15}
                ${doorLeftWidth1 + 100.27} ${doorTopHeight1 + 178.15}
                ${doorLeftWidth1 + 100.27} ${doorTopHeight1 + 153.93}
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 153.93}
                `"/>
      <polyline id="glass-8"
                data-name="glass"
                class="cls-14"
                :points="`
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 179.67}
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 203.89}
                ${doorLeftWidth1 + 100.27} ${doorTopHeight1 + 203.89}
                ${doorLeftWidth1 + 100.27} ${doorTopHeight1 + 179.67}
                ${doorLeftWidth1 + 81.97} ${doorTopHeight1 + 179.67}
                `"/>
      <polyline id="glass-9"
                data-name="glass"
                class="cls-15"
                :points="`
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 102.45}
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 126.67}
                ${doorLeftWidth1 + 58.42} ${doorTopHeight1 + 126.67}
                ${doorLeftWidth1 + 58.42} ${doorTopHeight1 + 102.45}
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 102.45}
                `"/>
      <polyline id="glass-10"
                data-name="glass"
                class="cls-16"
                :points="`
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 128.19}
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 152.41}
                ${doorLeftWidth1 + 58.42} ${doorTopHeight1 + 152.41}
                ${doorLeftWidth1 + 58.42} ${doorTopHeight1 + 128.19}
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 128.19}
                `"/>
      <polyline id="glass-11"
                data-name="glass"
                class="cls-17"
                :points="`
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 153.93}
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 178.15}
                ${doorLeftWidth1 + 58.42} ${doorTopHeight1 + 178.15}
                ${doorLeftWidth1 + 58.42} ${doorTopHeight1 + 153.93}
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 153.93}
                `"/>
      <polyline id="glass-12"
                data-name="glass"
                class="cls-18"
                :points="`
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 179.67}
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 203.89}
                ${doorLeftWidth1 + 58.42} ${doorTopHeight1 + 203.89}
                ${doorLeftWidth1 + 58.42} ${doorTopHeight1 + 179.67}
                ${doorLeftWidth1 + 40.13} ${doorTopHeight1 + 179.67}
                `"/>
      <path id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-19"
            :d="`
            M${doorLeftWidth1 + 1527},${doorTopHeight1 + 7473.34}
            h-69.85
            l0,28
            H${doorLeftWidth1 + 1527}
            Z
            m-4.89,23.13
            H${doorLeftWidth1 + 1462}
            v-18.24
            h60.08
            v18.24
            Z`"
            transform="translate(-1422.17 -7249.7)"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
